import {
  container2,
  question,
  inputWrapper,
  input,
  questionMain,
  errorClass,
} from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { getAnswersByQuestion } from "../../../api/secureApi";
import { EMPLOYMENT } from "./overview";
import { handleError } from "../../../utils/handleError";
import { RadioPS } from "./radioPS";
import { handleSave } from "./handleSave";
import { LOGIN } from "./lifeCircumstances";
import { Header } from "./header";

// additional json
const WHERE = "where";
const POSITION = "position";
const TIME_IN_JOB = "time_in_job";
const TIME_IN_FIELD = "time_in_field";

export function Employment() {
  const navigate = useNavigate();
  const ref0 = useRef();
  const ref1 = useRef();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { getValues, register, setValue, watch } = useForm({
    mode: "all",
    defaultValues: {
      [EMPLOYMENT]: "",
    },
  });
  const current = watch(EMPLOYMENT);

  useEffect(() => {
    window.scrollTo(0, 0);

    getAnswersByQuestion(EMPLOYMENT)
      .then((response) => {
        response.forEach((row) => {
          setValue(EMPLOYMENT, row.selection);
          if (row.additional_json) {
            if (row.additional_json[WHERE]) {
              setValue(WHERE, row.additional_json[WHERE]);
            }
            if (row.additional_json[POSITION]) {
              setValue(POSITION, row.additional_json[POSITION]);
            }
            if (row.additional_json[TIME_IN_JOB]) {
              setValue(TIME_IN_JOB, row.additional_json[TIME_IN_JOB]);
            }
            if (row.additional_json[TIME_IN_FIELD]) {
              setValue(TIME_IN_FIELD, row.additional_json[TIME_IN_FIELD]);
            }
          }
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error getting answers", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  }, [setValue, navigate]);

  useEffect(() => {
    if (current === "no") {
      setGenericErrorMessage("");
    }
  }, [current]);

  const handlePreSave = (path) => {
    const values = getValues();
    if (values.employment) {
      if (
        values.employment === "yes" &&
        values.where &&
        values.position &&
        values.time_in_job &&
        values.time_in_field
      ) {
        // eslint-disable-next-line no-console
        console.log("values", values);
        setGenericErrorMessage("");
        const newDetails = [
          {
            selection: values.employment,
            additional_json: {
              where: values.where,
              position: values.position,
              time_in_job: values.time_in_job,
              time_in_field: values.time_in_field,
            },
          },
        ];
        handleSave(EMPLOYMENT, newDetails, navigate, path);
      } else if (values.employment === "no") {
        setGenericErrorMessage("");
        const newDetails = [
          {
            selection: values.employment,
          },
        ];
        handleSave(EMPLOYMENT, newDetails, navigate, path);
      } else if (
        values.employment === "yes" &&
        (!values.where || !values.position || !values.time_in_job || !values.time_in_field)
      ) {
        setGenericErrorMessage("Please fill in all answers.");
      }
    }
  };

  const handleBackClick = () => {
    const path = "/statement/pronouns";
    handlePreSave(path);
  };

  const handleNextClick = () => {
    const path = "/statement/education";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section>
        <div className={container2}>
          <div className={questionMain}>Are you currently employed?</div>
          <div className="pb-4">
            <RadioPS
              innerRef={ref0}
              name={EMPLOYMENT}
              answer="yes"
              label="yes"
              register={register}
              {...register(EMPLOYMENT)}
              current={current}
            />
            <RadioPS
              innerRef={ref1}
              name={EMPLOYMENT}
              answer="no"
              label="no"
              register={register}
              {...register(EMPLOYMENT)}
              current={current}
            />
          </div>
          {current === "yes" && (
            <div>
              <div className={question}>where do you work?</div>
              <div className={inputWrapper}>
                <input
                  placeholder="ACME"
                  type="text"
                  name={WHERE}
                  id={WHERE}
                  className={input}
                  {...register(WHERE)}
                />
              </div>
              <div className={question}>what is your position at work?</div>
              <div className={inputWrapper}>
                <input
                  placeholder="Safety Inspector"
                  type="text"
                  name={POSITION}
                  id={POSITION}
                  className={input}
                  {...register(POSITION)}
                />
              </div>
              <div className={question}>how long have you had this job?</div>
              <div className={inputWrapper}>
                <input
                  placeholder="2 years"
                  type="text"
                  name={TIME_IN_JOB}
                  id={TIME_IN_JOB}
                  className={input}
                  {...register(TIME_IN_JOB)}
                />
              </div>
              <div className={question}>how long have you worked in this field?</div>
              <div className={inputWrapper}>
                <input
                  placeholder="6 years"
                  type="text"
                  name={TIME_IN_FIELD}
                  id={TIME_IN_FIELD}
                  className={input}
                  {...register(TIME_IN_FIELD)}
                />
              </div>
            </div>
          )}
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <ButtonBackNext
            handleBackClick={handleBackClick}
            handleNextClick={handleNextClick}
            disabledNext={!current}
          />
        </div>
      </section>
    </>
  );
}
