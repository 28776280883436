export const STATUS_APP_SENT_TO_BCI = "APP_SENT_TO_BCI";
export const STATUS_APPROVED = "APPROVED";
export const STATUS_BCI_APP_SENT_TO_CUSTOMER = "BCI_APP_SENT_TO_CUSTOMER";
export const STATUS_CONTACTED = "CONTACTED";
export const STATUS_DID_NOT_HIRE = "DID_NOT_HIRE";
export const STATUS_FIRST_PAYMENT_RECEIVED = "FIRST_PAYMENT_RECEIVED";
export const STATUS_INVOICE_SENT = "INVOICE_SENT";
export const STATUS_LEGAL_DENIAL_DECLINED_CASE = "LEGAL_DENIAL_DECLINED_CASE";
export const STATUS_LEGAL_DENIAL_DECLINED_CASE_NOTIFIED = "LEGAL_DENIAL_DECLINED_CASE_NOTIFIED";
export const STATUS_LEGAL_DENIAL_NOT_ELIGIBLE = "LEGAL_DENIAL_NOT_ELIGIBLE";
export const STATUS_LEGAL_DENIAL_NOT_ELIGIBLE_NOTIFIED = "LEGAL_DENIAL_NOT_ELIGIBLE_NOTIFIED";
export const STATUS_NEED_MORE_INFO_CLIENT = "NEED_MORE_INFO_CLIENT";
export const STATUS_NEED_MORE_INFO_SALES = "NEED_MORE_INFO_SALES";
export const STATUS_NEW_CONTACT_REQUESTED = "NEW_CONTACT_REQUESTED";
export const STATUS_NEW_ELIGIBLE = "NEW_ELIGIBLE";
export const STATUS_NEW_MESSAGED = "NEW_MESSAGED";
export const STATUS_NEW_NO_RESULTS = "NEW_NO_RESULTS";
export const STATUS_NEW_NOT_ELIGIBLE = "NEW_NOT_ELIGIBLE";
export const STATUS_NEW_SCHEDULED = "NEW_SCHEDULED";
export const STATUS_PREPPED = "PREPPED";
export const STATUS_READY_FOR_DRAFTING = "READY_FOR_DRAFTING";
export const STATUS_SA_DRAFTED = "SA_DRAFTED";
export const STATUS_SA_SENT = "SA_SENT";
export const STATUS_SA_SIGNED = "SA_SIGNED";
export const STATUS_THINKING_ABOUT_IT_ELIGIBLE = "THINKING_ABOUT_IT_ELIGIBLE";
export const STATUS_THINKING_ABOUT_IT_NOT_ELIGIBLE = "THINKING_ABOUT_IT_NOT_ELIGIBLE";
export const STATUS_WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL";

export const ALL_WORKFLOW_STATUSES = [
  STATUS_APP_SENT_TO_BCI,
  STATUS_APPROVED,
  STATUS_BCI_APP_SENT_TO_CUSTOMER,
  STATUS_CONTACTED,
  STATUS_DID_NOT_HIRE,
  STATUS_FIRST_PAYMENT_RECEIVED,
  STATUS_INVOICE_SENT,
  STATUS_LEGAL_DENIAL_DECLINED_CASE,
  STATUS_LEGAL_DENIAL_DECLINED_CASE_NOTIFIED,
  STATUS_LEGAL_DENIAL_NOT_ELIGIBLE,
  STATUS_LEGAL_DENIAL_NOT_ELIGIBLE_NOTIFIED,
  STATUS_NEED_MORE_INFO_CLIENT,
  STATUS_NEED_MORE_INFO_SALES,
  STATUS_NEW_CONTACT_REQUESTED,
  STATUS_NEW_ELIGIBLE,
  STATUS_NEW_NO_RESULTS,
  STATUS_NEW_NOT_ELIGIBLE,
  STATUS_NEW_SCHEDULED,
  STATUS_PREPPED,
  STATUS_READY_FOR_DRAFTING,
  STATUS_SA_DRAFTED,
  STATUS_SA_SENT,
  STATUS_SA_SIGNED,
  STATUS_THINKING_ABOUT_IT_ELIGIBLE,
  STATUS_THINKING_ABOUT_IT_NOT_ELIGIBLE,
  STATUS_WAITING_FOR_APPROVAL,
];

export const DO_NOT_SHOW_MENU_STATUSES = [
  STATUS_APPROVED,
  STATUS_CONTACTED,
  STATUS_DID_NOT_HIRE,
  STATUS_LEGAL_DENIAL_DECLINED_CASE,
  STATUS_LEGAL_DENIAL_DECLINED_CASE_NOTIFIED,
  STATUS_LEGAL_DENIAL_NOT_ELIGIBLE,
  STATUS_LEGAL_DENIAL_NOT_ELIGIBLE_NOTIFIED,
  STATUS_NEED_MORE_INFO_CLIENT,
  STATUS_NEED_MORE_INFO_SALES,
  STATUS_NEW_CONTACT_REQUESTED,
  STATUS_NEW_ELIGIBLE,
  STATUS_NEW_MESSAGED,
  STATUS_NEW_NO_RESULTS,
  STATUS_NEW_NOT_ELIGIBLE,
  STATUS_NEW_SCHEDULED,
  STATUS_PREPPED,
  STATUS_SA_DRAFTED,
  STATUS_THINKING_ABOUT_IT_ELIGIBLE,
  STATUS_THINKING_ABOUT_IT_NOT_ELIGIBLE,
  STATUS_WAITING_FOR_APPROVAL,
];

export const rasaWorkflowStatus = [
  {
    index: 1,
    display: "New",
    client_states: [
      STATUS_NEW_CONTACT_REQUESTED,
      STATUS_NEW_ELIGIBLE,
      STATUS_NEW_MESSAGED,
      STATUS_NEW_NO_RESULTS,
      STATUS_NEW_NOT_ELIGIBLE,
      STATUS_NEW_SCHEDULED,
    ],
  },
  { index: 2, display: "Prepped", client_states: [STATUS_PREPPED] },
  { index: 3, display: "Waiting for Approval", client_states: [STATUS_WAITING_FOR_APPROVAL] },
  { index: 4, display: "Legal Approved", client_states: [STATUS_APPROVED] },
  { index: 5, display: "Contacted", client_states: [STATUS_CONTACTED] },
  { index: 6, display: "SA Sent", client_states: [STATUS_SA_SENT] },
  { index: 7, display: "SA Signed", client_states: [STATUS_SA_SIGNED] },
  { index: 8, display: "Invoice Sent", client_states: [STATUS_INVOICE_SENT] },
  { index: 9, display: "First payment received", client_states: [STATUS_FIRST_PAYMENT_RECEIVED] },
  { index: 10, display: "Ready for drafting", client_states: [STATUS_READY_FOR_DRAFTING] },
];
