import {
  container2,
  errorClass,
  inputWrapper,
  pinkSection,
  question,
  questionMain,
  textareaClass,
} from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "../../../components/checkbox";
import { useEffect, useState } from "react";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { BARRIERS } from "./overview";
import { handleDetailsChange, handleSave, setInitialValues } from "./handleSave";
import { LOGIN } from "./lifeCircumstances";
import { Header } from "./header";
import { BarrierDetails } from "./barrierDetails";

// selections
const DENIED_JOBS = "denied jobs";
const DENIED_PROMOTIONS = "denied promotions";
const DENIED_HOUSING = "denied housing";
const DENIED_EDUCATION = "denied education opportunity";
const DENIED_VOLUNTEER_WORK = "denied volunteer work";
const TRAVEL_RESTRICTIONS = "travel restrictions";
const SOCIAL_JUDGEMENT = "social judgement / stigma";
const OTHER = "other";
const FREE_ANSWER = "free answer";

// additional details
export const DETAILS = "details";

export function Barriers() {
  const navigate = useNavigate();
  const [currentValues, setCurrentValues] = useState([]);
  const [details, setDetails] = useState([]);
  const [showHousingDetailInput, setShowHousingDetailInput] = useState(false);
  const [showEducationDetailInput, setShowEducationDetailInput] = useState(false);
  const [showJobDetailInput, setShowJobDetailInput] = useState(false);
  const [showPromotionDetailInput, setShowPromotionDetailInput] = useState(false);
  const [showVolunteerDetailInput, setShowVolunteerDetailInput] = useState(false);
  const [showTravelDetailInput, setShowTravelDetailInput] = useState(false);
  const [showStigmaDetailInput, setShowStigmaDetailInput] = useState(false);
  const [showOtherDetailInput, setShowOtherDetailInput] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setInitialValues(BARRIERS, setCurrentValues, setDetails, navigate, setGenericErrorMessage);
  }, [navigate]);

  useEffect(() => {
    // if selection has additional questions, show them
    setShowJobDetailInput(currentValues.includes(DENIED_JOBS));
    setShowPromotionDetailInput(currentValues.includes(DENIED_PROMOTIONS));
    setShowHousingDetailInput(currentValues.includes(DENIED_HOUSING));
    setShowEducationDetailInput(currentValues.includes(DENIED_EDUCATION));
    setShowVolunteerDetailInput(currentValues.includes(DENIED_VOLUNTEER_WORK));
    setShowTravelDetailInput(currentValues.includes(TRAVEL_RESTRICTIONS));
    setShowStigmaDetailInput(currentValues.includes(SOCIAL_JUDGEMENT));
    setShowOtherDetailInput(currentValues.includes(OTHER));
  }, [currentValues]);

  const handlePreSave = (path) => {
    if (currentValues.length > 0) {
      setGenericErrorMessage("");
      //   loop through details, if doesn't exist in values then delete it in details
      const currentDetails = [...details];
      const newDetails = currentDetails.filter((obj) => currentValues.includes(obj.selection));
      currentValues.forEach((e) => {
        if (!currentDetails.find((el) => el.selection === e)) {
          newDetails.push({ selection: e });
        }
      });
      setDetails(newDetails);
      // eslint-disable-next-line no-console
      console.log("details to save", newDetails);
      handleSave(BARRIERS, newDetails, navigate, path);
    } else {
      navigate(path);
    }
  };

  const handleBackClick = () => {
    const path = "/statement/probation";
    handlePreSave(path);
  };

  const handleNextClick = () => {
    const path = "/statement/reasons";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section>
        <form>
          <div className={container2}>
            <h2 className={questionMain}>How has having this criminal record been a barrier?</h2>
            <div>
              <div className={showJobDetailInput ? pinkSection : ""}>
                <Checkbox
                  value={DENIED_JOBS}
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                {showJobDetailInput && (
                  <BarrierDetails
                    selection={DENIED_JOBS}
                    details={details}
                    setDetails={setDetails}
                    placeholder="fired after background check"
                  />
                )}
              </div>
            </div>

            <div>
              <div className={showPromotionDetailInput ? pinkSection : ""}>
                <Checkbox
                  value={DENIED_PROMOTIONS}
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                {showPromotionDetailInput && (
                  <BarrierDetails
                    selection={DENIED_PROMOTIONS}
                    details={details}
                    setDetails={setDetails}
                    placeholder="cannot get security clearance to work with some clients"
                  />
                )}
              </div>
            </div>

            <div>
              <div className={showHousingDetailInput ? pinkSection : ""}>
                <Checkbox
                  value={DENIED_HOUSING}
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                {showHousingDetailInput && (
                  <BarrierDetails
                    selection={DENIED_HOUSING}
                    details={details}
                    setDetails={setDetails}
                    placeholder="rental applications denied"
                  />
                )}
              </div>
            </div>

            <div>
              <div className={showEducationDetailInput ? pinkSection : ""}>
                <Checkbox
                  value={DENIED_EDUCATION}
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                {showEducationDetailInput && (
                  <BarrierDetails
                    selection={DENIED_EDUCATION}
                    details={details}
                    setDetails={setDetails}
                    placeholder="could not get into Nursing program with record"
                  />
                )}
              </div>
            </div>

            <div>
              <div className={showVolunteerDetailInput ? pinkSection : ""}>
                <Checkbox
                  value={DENIED_VOLUNTEER_WORK}
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                {showVolunteerDetailInput && (
                  <BarrierDetails
                    selection={DENIED_VOLUNTEER_WORK}
                    details={details}
                    setDetails={setDetails}
                    placeholder="cannot volunteer at my child's school"
                  />
                )}
              </div>
            </div>

            <div>
              <div className={showTravelDetailInput ? pinkSection : ""}>
                <Checkbox
                  value={TRAVEL_RESTRICTIONS}
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                {showTravelDetailInput && (
                  <BarrierDetails
                    selection={TRAVEL_RESTRICTIONS}
                    details={details}
                    setDetails={setDetails}
                    placeholder="could not go on a family trip to Canada due to my record"
                  />
                )}
              </div>
            </div>

            <div>
              <div className={showStigmaDetailInput ? pinkSection : ""}>
                <Checkbox
                  value={SOCIAL_JUDGEMENT}
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                {showStigmaDetailInput && (
                  <BarrierDetails
                    selection={SOCIAL_JUDGEMENT}
                    details={details}
                    setDetails={setDetails}
                    placeholder=""
                  />
                )}
              </div>
            </div>

            <div>
              <div className={showOtherDetailInput ? pinkSection : ""}>
                <Checkbox
                  value={OTHER}
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                {showOtherDetailInput && (
                  <BarrierDetails
                    selection={OTHER}
                    details={details}
                    setDetails={setDetails}
                    placeholder=""
                  />
                )}
              </div>
            </div>

            <div className={question}>please add other or additional barriers, if applicable:</div>
            <div className={inputWrapper}>
              <textarea
                type="text"
                name={FREE_ANSWER}
                id={FREE_ANSWER}
                className={textareaClass}
                rows="6"
                onChange={(e) => handleDetailsChange(e, FREE_ANSWER, DETAILS, details, setDetails)}
                defaultValue={
                  details.find(
                    (item) =>
                      item.selection === FREE_ANSWER &&
                      item.additional_json &&
                      item.additional_json[DETAILS],
                  )?.additional_json[DETAILS] || ""
                }
              ></textarea>
            </div>
            {genericErrorMessage && (
              <div className={errorClass} id="error">
                {genericErrorMessage}
              </div>
            )}
            <ButtonBackNext
              handleBackClick={handleBackClick}
              handleNextClick={handleNextClick}
              disabledNext={currentValues.length === 0}
            />
          </div>
        </form>
      </section>
    </>
  );
}
