import {
  container2,
  errorClass,
  question2,
  questionMain,
  textareaClass,
} from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { getAnswersByQuestion } from "../../../api/secureApi";
import { useForm } from "react-hook-form";
import { ADDITIONAL_INFO } from "./overview";
import { handleError } from "../../../utils/handleError";
import { handleSave } from "./handleSave";
import { useEffect, useState } from "react";
import { LOGIN } from "./lifeCircumstances";
import { Header } from "./header";
import { RadioPS } from "./radioPS";

// additional
const DETAILS = "details";
export const ADDITIONAL_INFO_BOOL = "additional_info_bool";

export function AdditionalInfo() {
  const navigate = useNavigate();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { getValues, register, setValue, watch } = useForm({
    mode: "all",
    defaultValues: {
      [ADDITIONAL_INFO_BOOL]: "",
      [ADDITIONAL_INFO]: "",
    },
  });
  const current = watch(ADDITIONAL_INFO_BOOL);

  useEffect(() => {
    window.scrollTo(0, 0);

    getAnswersByQuestion(ADDITIONAL_INFO)
      .then((response) => {
        response.forEach((row) => {
          if (row.additional_json) {
            if (row.additional_json[DETAILS]) {
              setValue(DETAILS, row.additional_json[DETAILS]);
            }
            if (row.additional_json[ADDITIONAL_INFO_BOOL]) {
              setValue(ADDITIONAL_INFO_BOOL, row.additional_json[ADDITIONAL_INFO_BOOL]);
            }
          }
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error getting answers", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  }, [setValue, navigate]);

  useEffect(() => {
    if (current === "no") {
      setGenericErrorMessage("");
      setValue(DETAILS, "");
    }
  }, [current, setValue]);

  const handlePreSave = (path) => {
    const values = getValues();
    if (values[ADDITIONAL_INFO_BOOL] === "yes" && values[DETAILS] === "") {
      setGenericErrorMessage("please fill in details");
    } else if (values[ADDITIONAL_INFO_BOOL] === "no") {
      setGenericErrorMessage("");
      const newDetails = [
        {
          selection: ADDITIONAL_INFO,
          additional_json: {
            [ADDITIONAL_INFO_BOOL]: values[ADDITIONAL_INFO_BOOL],
          },
        },
      ];
      handleSave(ADDITIONAL_INFO, newDetails, navigate, path);
    } else {
      // eslint-disable-next-line no-console
      console.log("values", values);
      setGenericErrorMessage("");
      const newDetails = [
        {
          selection: ADDITIONAL_INFO,
          additional_json: {
            [DETAILS]: values[DETAILS],
            [ADDITIONAL_INFO_BOOL]: values[ADDITIONAL_INFO_BOOL],
          },
        },
      ];
      handleSave(ADDITIONAL_INFO, newDetails, navigate, path);
    }
  };

  const handleBackClick = () => {
    const path = "/statement/life-improvement";
    handlePreSave(path);
  };

  const handleNextClick = () => {
    const path = "/statement/final";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section id="heading">
        <div className={container2}>
          <div className={questionMain}>
            is there is any additional information you want us to share with the court?
          </div>
          <div className="flex justify-start">
            <div className="mr-2">
              <RadioPS
                name={ADDITIONAL_INFO_BOOL}
                answer="yes"
                label="yes"
                register={register}
                {...register(ADDITIONAL_INFO_BOOL)}
                current={current}
              />
            </div>
            <RadioPS
              name={ADDITIONAL_INFO_BOOL}
              answer="no"
              label="no"
              register={register}
              {...register(ADDITIONAL_INFO_BOOL)}
              current={current}
            />
          </div>
          {current === "yes" ? (
            <>
              <div className={question2}>add additional information here:</div>
              <textarea
                type="text"
                name={DETAILS}
                id={DETAILS}
                className={textareaClass}
                rows="10"
                {...register(DETAILS)}
              ></textarea>
            </>
          ) : (
            <div className="h-6" />
          )}
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <ButtonBackNext
            handleBackClick={handleBackClick}
            handleNextClick={handleNextClick}
            disabledNext={!current}
          />
        </div>
      </section>
    </>
  );
}
