import {
  questionMain,
  inputWrapper,
  question,
  textareaClass,
  textareaClassPink,
  inputPink,
  buttonFlexContainer,
  animate,
  pinkSection,
  container2,
  errorClass,
  question2,
} from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Checkbox } from "../../../components/checkbox";
import { ButtonNext } from "../../../components/buttonNext";
import { handleSave, setInitialValues } from "./handleSave";
import { CIRCUMSTANCES } from "./overview";
import { Header } from "./header";
import { RadioPS } from "./radioPS";
import { useForm } from "react-hook-form";

// selections
const SOCIAL_PRESSURE = "socialized with the 'wrong crowd'";
const CHILDHOOD_TRAUMA = "childhood trauma";
export const SUBSTANCE_USE = "substance use (alcohol/ drugs)";
const DEATH_OF_LOVED_ONE = "death of a loved one";
const OTHER = "other";
const FREE_ANSWER = "free answer";

// additional details
export const PROGRAM_BOOL = "substance_program_bool";
export const PROGRAM = "substance_program";
export const SOBRIETY_BOOL = "sobriety_bool";
export const SOBRIETY_TIME = "sobriety_time";
export const SOCIAL_DETAIL = "social_detail";
export const LOVED_ONE = "loved_one";
export const TRAUMA_DETAIL = "trauma_detail";
export const OTHER_DETAIL = "other_detail";

const DETAILS = "details";

// path
export const LOGIN = "/login";

export function LifeCircumstances() {
  const navigate = useNavigate();
  const [currentValues, setCurrentValues] = useState([]);
  const [details, setDetails] = useState([]);
  const [showSubstanceDetailInput, setShowSubstanceDetailInput] = useState(false);
  const [showLossDetailInput, setShowLossDetailInput] = useState(false);
  const [showSocialDetailDetailInput, setShowSocialDetailDetailInput] = useState(false);
  const [showTraumaDetailInput, setShowTraumaDetailInput] = useState(false);
  const [showOtherDetailInput, setShowOtherDetailInput] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { getValues, register, setValue, watch } = useForm({
    mode: "all",
    defaultValues: {
      [PROGRAM_BOOL]: "",
      [SOBRIETY_BOOL]: "",
    },
  });
  const currentProgramBool = watch(PROGRAM_BOOL);
  const currentSobrietyBool = watch(SOBRIETY_BOOL);

  useEffect(() => {
    setInitialValues(CIRCUMSTANCES, setCurrentValues, setDetails, navigate, setGenericErrorMessage);
  }, [navigate]);

  useEffect(() => {
    const program =
      details.find((item) => item.additional_json && item.additional_json[PROGRAM_BOOL])
        ?.additional_json[PROGRAM_BOOL] || "";
    const sobriety =
      details.find((item) => item.additional_json && item.additional_json[SOBRIETY_BOOL])
        ?.additional_json[SOBRIETY_BOOL] || "";
    if (program) {
      setValue(PROGRAM_BOOL, program);
    }
    if (sobriety) {
      setValue(SOBRIETY_BOOL, sobriety);
    }
  }, [details, setValue]);

  useEffect(() => {
    // if selection has additional questions, show them
    setShowSocialDetailDetailInput(currentValues.includes(SOCIAL_PRESSURE));
    setShowLossDetailInput(currentValues.includes(DEATH_OF_LOVED_ONE));
    setShowSubstanceDetailInput(currentValues.includes(SUBSTANCE_USE));
    setShowTraumaDetailInput(currentValues.includes(CHILDHOOD_TRAUMA));
    setShowOtherDetailInput(currentValues.includes(OTHER));
  }, [currentValues]);

  const handleDetailsChange = (e, selectionName, detailName) => {
    const newDetail = e.target.value;
    const currentDetails = [...details];
    const currentSelection = currentDetails.find((item) => item.selection === selectionName);
    if (currentSelection) {
      if (currentSelection.additional_json && currentSelection.additional_json[detailName]) {
        currentSelection.additional_json[detailName] = newDetail;
      } else {
        currentSelection.additional_json = { [detailName]: newDetail };
      }
    } else {
      currentDetails.push({
        selection: selectionName,
        additional_json: { [detailName]: newDetail },
      });
    }
    // eslint-disable-next-line no-console
    console.log("currentDetails", currentDetails);
    setDetails(currentDetails);
  };

  const handlePreSave = (path) => {
    const values = getValues();
    if (currentValues.length > 0 || details.length > 0 || values.length > 0) {
      //   loop through details, if doesn't exist in values then delete it in details
      //   if it's other, delete only if empty?
      const currentDetails = [...details];
      const newDetails = [];
      // include the currentValues that don't have details
      currentValues.forEach((val) => {
        const curr = currentDetails.find((el) => el.selection === val);
        if (curr) {
          if (val === SUBSTANCE_USE) {
            newDetails.push({
              selection: val,
              additional_json: {
                [PROGRAM_BOOL]: values[PROGRAM_BOOL],
                [PROGRAM]:
                  currentDetails.find(
                    (item) => item.additional_json && item.additional_json[PROGRAM],
                  )?.additional_json[PROGRAM] || "",
                [SOBRIETY_BOOL]: values[SOBRIETY_BOOL],
                [SOBRIETY_TIME]:
                  currentDetails.find(
                    (item) => item.additional_json && item.additional_json[SOBRIETY_TIME],
                  )?.additional_json[SOBRIETY_TIME] || "",
              },
            });
          } else {
            // selection: val, additional_json: {something: detail}
            newDetails.push(curr);
          }
        } else {
          newDetails.push({ selection: val });
        }
      });
      // if we saved FREE_ANSWER as a selection but then the value got set back to "",
      // don't save that
      const freeAnswerIndex = newDetails.findIndex(
        (item) => item.selection === FREE_ANSWER && item.additional_json[DETAILS] === "",
      );
      if (freeAnswerIndex > -1) {
        newDetails.splice(freeAnswerIndex, 1);
      }
      setDetails(newDetails);
      handleSave(CIRCUMSTANCES, newDetails, navigate, path);
    } else {
      navigate(path);
    }
  };

  const handleNextClick = () => {
    const path = "/statement/probation";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section id="form">
        <div className={container2}>
          <h2 className={questionMain}>
            what circumstances in your life led to your involvement with the justice system? (please
            select all that apply.)
          </h2>
          <div>
            <div className={showSocialDetailDetailInput ? pinkSection : ""}>
              <Checkbox
                value={SOCIAL_PRESSURE}
                currentValues={currentValues}
                setCurrentValues={setCurrentValues}
              />
              {showSocialDetailDetailInput && (
                <div className={animate}>
                  <div className={question}>please give brief details</div>
                  <div className={inputWrapper}>
                    <textarea
                      placeholder=""
                      type="text"
                      name={TRAUMA_DETAIL}
                      id={TRAUMA_DETAIL}
                      className={textareaClassPink}
                      rows="4"
                      onChange={(e) => handleDetailsChange(e, SOCIAL_PRESSURE, SOCIAL_DETAIL)}
                      defaultValue={
                        details.find(
                          (item) => item.additional_json && item.additional_json[SOCIAL_DETAIL],
                        )?.additional_json[SOCIAL_DETAIL] || ""
                      }
                    ></textarea>
                  </div>
                </div>
              )}
            </div>
            <div className={showLossDetailInput ? pinkSection : ""}>
              <Checkbox
                value={DEATH_OF_LOVED_ONE}
                currentValues={currentValues}
                setCurrentValues={setCurrentValues}
              />
              {showLossDetailInput && (
                <div className={animate}>
                  <div className={question}>who was your loved one?</div>
                  <div className={inputWrapper}>
                    <input
                      placeholder="father, sister, close friend"
                      type="text"
                      name={LOVED_ONE}
                      id={LOVED_ONE}
                      className={inputPink}
                      onChange={(e) => handleDetailsChange(e, DEATH_OF_LOVED_ONE, LOVED_ONE)}
                      defaultValue={
                        details.find(
                          (item) => item.additional_json && item.additional_json[LOVED_ONE],
                        )?.additional_json[LOVED_ONE] || ""
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={showSubstanceDetailInput ? pinkSection : ""}>
              <Checkbox
                value={SUBSTANCE_USE}
                currentValues={currentValues}
                setCurrentValues={setCurrentValues}
              />
              {showSubstanceDetailInput && (
                <div className={animate}>
                  <div className={question}>did you complete a substance treatment program?</div>
                  <div className="flex justify-start">
                    <div className="mr-2">
                      <RadioPS
                        name={PROGRAM_BOOL}
                        answer="yes"
                        label="yes"
                        register={register}
                        {...register(PROGRAM_BOOL)}
                        current={currentProgramBool}
                      />
                    </div>
                    <RadioPS
                      name={PROGRAM_BOOL}
                      answer="no"
                      label="no"
                      register={register}
                      {...register(PROGRAM_BOOL)}
                      current={currentProgramBool}
                    />
                  </div>
                  {currentProgramBool === "yes" && (
                    <>
                      <div className={question2}>what was the name of the program?</div>
                      <div className={inputWrapper}>
                        <input
                          type="text"
                          name={PROGRAM}
                          id={PROGRAM}
                          className={inputPink}
                          onChange={(e) => handleDetailsChange(e, SUBSTANCE_USE, PROGRAM)}
                          defaultValue={
                            details.find(
                              (item) => item.additional_json && item.additional_json[PROGRAM],
                            )?.additional_json[PROGRAM] || ""
                          }
                        />
                      </div>
                    </>
                  )}

                  <div className={question}>have you have maintained sobriety?</div>
                  <div className="flex justify-start">
                    <div className="mr-2">
                      <RadioPS
                        name={SOBRIETY_BOOL}
                        answer="yes"
                        label="yes"
                        register={register}
                        {...register(SOBRIETY_BOOL)}
                        current={currentSobrietyBool}
                      />
                    </div>
                    <RadioPS
                      name={SOBRIETY_BOOL}
                      answer="no"
                      label="no"
                      register={register}
                      {...register(SOBRIETY_BOOL)}
                      current={currentSobrietyBool}
                    />
                  </div>
                  {currentSobrietyBool === "yes" && (
                    <>
                      <div className={question2}>how long have you maintained sobriety?</div>
                      <div className={inputWrapper}>
                        <input
                          placeholder="3 years"
                          type="text"
                          name={SOBRIETY_TIME}
                          id={SOBRIETY_TIME}
                          className={inputPink}
                          onChange={(e) => handleDetailsChange(e, SUBSTANCE_USE, SOBRIETY_TIME)}
                          defaultValue={
                            details.find(
                              (item) => item.additional_json && item.additional_json[SOBRIETY_TIME],
                            )?.additional_json[SOBRIETY_TIME] || ""
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className={showTraumaDetailInput ? pinkSection : ""}>
              <Checkbox
                value={CHILDHOOD_TRAUMA}
                currentValues={currentValues}
                setCurrentValues={setCurrentValues}
              />
              {showTraumaDetailInput && (
                <div className={animate}>
                  <div className={question}>please give brief details</div>
                  <div className={inputWrapper}>
                    <textarea
                      placeholder="abuse, neglect, in foster care system, parents' divorce, parent incarcerated"
                      type="text"
                      name={TRAUMA_DETAIL}
                      id={TRAUMA_DETAIL}
                      className={textareaClassPink}
                      rows="4"
                      onChange={(e) => handleDetailsChange(e, CHILDHOOD_TRAUMA, TRAUMA_DETAIL)}
                      defaultValue={
                        details.find(
                          (item) => item.additional_json && item.additional_json[TRAUMA_DETAIL],
                        )?.additional_json[TRAUMA_DETAIL] || ""
                      }
                    ></textarea>
                  </div>
                </div>
              )}
            </div>

            <div className={showOtherDetailInput ? pinkSection : ""}>
              <Checkbox
                value={OTHER}
                currentValues={currentValues}
                setCurrentValues={setCurrentValues}
              />
              {showOtherDetailInput && (
                <div className={animate}>
                  <div className={question}>please give brief details</div>
                  <div className={inputWrapper}>
                    <textarea
                      type="text"
                      name={OTHER_DETAIL}
                      id={OTHER_DETAIL}
                      className={textareaClassPink}
                      rows="4"
                      onChange={(e) => handleDetailsChange(e, OTHER, OTHER_DETAIL)}
                      defaultValue={
                        details.find(
                          (item) => item.additional_json && item.additional_json[OTHER_DETAIL],
                        )?.additional_json[OTHER_DETAIL] || ""
                      }
                    ></textarea>
                  </div>
                </div>
              )}
            </div>

            <div className={question}>please add other or additional circumstances:</div>
            <div className={inputWrapper}>
              <textarea
                type="text"
                name={FREE_ANSWER}
                id={FREE_ANSWER}
                className={textareaClass}
                rows="6"
                onChange={(e) => handleDetailsChange(e, FREE_ANSWER, DETAILS)}
                defaultValue={
                  details.find(
                    (item) =>
                      item.selection === FREE_ANSWER &&
                      item.additional_json &&
                      item.additional_json[DETAILS],
                  )?.additional_json[DETAILS] || ""
                }
              ></textarea>
            </div>
          </div>
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <div className={buttonFlexContainer}>
            <div></div>
            <ButtonNext handleClick={handleNextClick} disabled={currentValues.length === 0} />
          </div>
        </div>
      </section>
    </>
  );
}
