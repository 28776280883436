// partnership utm codes
export const nba_utm = "event_nba24_promo"; // in use until end of jan 2025
export const utmFree = [
  "davis-tech",
  "honestjobs_jul24_az",
  "demo",
  "watson-quickadd",
  "watson-quickadd-partnership",
];
export const REFERRAL_CLIENT = "referral-client";
export const REFERRALCLIENT = "referralclient";

export const PARTNERSHIP_ = "partnership_";

export const _PROMO = "_promo";

export const isFreeUtm = (utm_code) => {
  const utmCodeFromStorage = localStorage.getItem("utm_code"); // for nba event jan 2025
  if (utmCodeFromStorage && utmCodeFromStorage === nba_utm) {
    return true;
  } else {
    return (
      utmFree.includes(utm_code) ||
      (utm_code && utm_code.startsWith(PARTNERSHIP_)) ||
      (utm_code && utm_code.endsWith(_PROMO))
    );
  }
};

export const vocUtm = "partnership_voc";
