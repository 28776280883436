import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Checkbox } from "../../../components/checkbox";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { handleDetailsChange, handleSave, setInitialValues } from "./handleSave";
import { CLEARING_REASONS } from "./overview";
import {
  container2,
  errorClass,
  inputWrapperNoLabel,
  questionMain,
  textareaClass,
} from "../../../constants/classnames";
import { LOGIN } from "./lifeCircumstances";
import { Header } from "./header";

// selection
const FREE_ANSWER = "free answer";

// additional
const DETAILS = "details";

export function ClearingReasons() {
  const navigate = useNavigate();
  const [currentValues, setCurrentValues] = useState([]);
  const [details, setDetails] = useState([]);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setInitialValues(
      CLEARING_REASONS,
      setCurrentValues,
      setDetails,
      navigate,
      setGenericErrorMessage,
    );
  }, [navigate]);

  const handlePreSave = (path) => {
    const index = currentValues.indexOf(FREE_ANSWER);
    if (index > -1) {
      currentValues.splice(index, 1);
    }
    let totalSelections = currentValues.length;
    if (totalSelections > 3) {
      setGenericErrorMessage("Please select your most important 2 to 3 reasons.");
    } else if (totalSelections === 0 && details.length === 0) {
      navigate(path);
    } else {
      setGenericErrorMessage("");
      const newDetails = [];
      currentValues.forEach((value) => {
        newDetails.push({
          selection: value,
        });
      });
      // if FREE_ANSWER contains non-empty answer, add it
      const freeAnswerIndex = details.findIndex(
        (item) => item.selection === FREE_ANSWER && item.additional_json[DETAILS] !== "",
      );
      if (freeAnswerIndex > -1) {
        newDetails.push({
          selection: details[freeAnswerIndex].selection,
          additional_json: details[freeAnswerIndex].additional_json,
        });
      }
      handleSave(CLEARING_REASONS, newDetails, navigate, path);
    }
  };

  const handleBackClick = () => {
    const path = "/statement/barriers";
    handlePreSave(path);
  };

  const handleNextClick = () => {
    const path = "/statement/pronouns";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section id="form">
        <div className={container2}>
          <h2 className={questionMain}>
            Please select your top 2 to 3 reasons for clearing your record.
          </h2>
          <Checkbox
            value="to be able to volunteer at my children's school"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <Checkbox
            value="to be able to do volunteer work"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <Checkbox
            value="to eliminate the stigma associated with a record"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <Checkbox
            value="to get a college education"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <Checkbox
            value="to get a new job"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <Checkbox
            value="to get a professional license"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <Checkbox
            value="to get a promotion"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <Checkbox
            value="to improve ability to rent a house or apartment"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <Checkbox
            value="to increase self-confidence"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <Checkbox
            value="to obtain life insurance"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <Checkbox
            value="to restore my gun rights"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <Checkbox
            value="to travel freely"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <Checkbox
            value="to vote"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <Checkbox
            value="for religious reasons"
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
          <div className={inputWrapperNoLabel}>
            <textarea
              placeholder="other reason or additional information"
              type="text"
              name={FREE_ANSWER}
              id={FREE_ANSWER}
              className={textareaClass}
              rows="3"
              onChange={(e) => handleDetailsChange(e, FREE_ANSWER, DETAILS, details, setDetails)}
              defaultValue={
                details.find(
                  (item) =>
                    item.selection === FREE_ANSWER &&
                    item.additional_json &&
                    item.additional_json[DETAILS],
                )?.additional_json[DETAILS] || ""
              }
            ></textarea>
          </div>
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <ButtonBackNext
            handleBackClick={handleBackClick}
            handleNextClick={handleNextClick}
            disabledNext={currentValues.length < 2}
          />
        </div>
      </section>
    </>
  );
}
