import RasaLogo from "@images/rasa-logo.svg";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  errorUnderInput,
  inputLabel,
  inputWrapperFlex,
  primaryButton,
} from "../../../constants/classnames";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { getAnswersByQuestion, getPerson, resetPassword } from "../../../api/secureApi";
import { genericMessage, handleError } from "../../../utils/handleError";
import { useNavigate } from "react-router-dom";
import { determineRoute } from "./login";
import { loading } from "../../../components/loading";

export function ResetPassword() {
  const navigate = useNavigate();

  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    const queryString = window.location.search;
    // eslint-disable-next-line no-console
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("code");
    // eslint-disable-next-line no-console
    console.log(token);
    localStorage.setItem("token", token);
  }, []);

  const handleMatchPasswordBlur = () => {
    const password = getValues("password");
    if (password !== confirmPassword && confirmPassword !== "") {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
      setGenericErrorMessage("");
    }
  };

  const onSubmit = () => {
    const password = getValues("password");
    const token = localStorage.getItem("token");
    const config = {
      access_token: token,
      password: password,
    };
    resetPassword(config)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response from reset pw", response);
        getPerson()
          .then((person) => {
            getAnswersByQuestion("auxiliary_campaign")
              .then((response) => {
                if (response.length > 0 && response[0].selection) {
                  localStorage.setItem("utm_code", response[0].selection);
                }
                determineRoute(person, navigate, setIsLoading);
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log("error getting answers", error);
                determineRoute(person, navigate, setIsLoading);
              });
          })
          .catch((errorId) => {
            // eslint-disable-next-line no-console
            console.log("errorId----", errorId);
            handleError(errorId, setGenericErrorMessage, navigate);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error from reset password:", error);
        setGenericErrorMessage(genericMessage);
      });
  };

  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          {/*logo*/}
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="mb-6 text-center font-serif text-2xl">reset password</h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto mb-16 mt-10 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <label htmlFor="password" className={inputLabel}>
                password *
              </label>
              <div className={inputWrapperFlex}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  className="-mr-7 block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="password-error"
                  {...register("password", { required: true, minLength: 8 })}
                  onBlur={handleMatchPasswordBlur}
                />
                {showPassword ? (
                  <EyeSlashIcon
                    className="relative -left-5 top-4 h-7 text-gray-400"
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <EyeIcon
                    className="relative -left-5 top-4 h-7 text-gray-400"
                    onClick={() => setShowPassword(true)}
                  />
                )}
              </div>
              <div className="mt-2 text-sm text-gray-400">
                Password should be at least 8 characters long.
              </div>
              {errors.password && (
                <div className={errorUnderInput} id="password-error">
                  Password is required and should be at least 8 characters long.
                </div>
              )}
            </div>
            <div className="pb-4">
              <label htmlFor="confirm-password" className={inputLabel}>
                confirm password *
              </label>
              <div className={inputWrapperFlex}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="confirm-password"
                  id="confirm-password"
                  className="-mr-7 block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  // placeholder="Create a password"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="confirm-password-error"
                  onBlur={handleMatchPasswordBlur}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              {passwordMatchError && (
                <div className={errorUnderInput} id="confirm-password-error">
                  Passwords do not match.
                </div>
              )}
            </div>
            <div className="flex flex-col justify-center">
              <button type="submit" className={primaryButton}>
                {isLoading ? loading : "reset password"}
              </button>
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
