import { container, container2, header2 } from "../../../constants/classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonBackNext } from "../../../components/buttonBackNext";

export function Final() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleBackClick = () => {
    navigate("/statement/additional-info", { state: state });
  };

  const handleClick = () => {
    // todo here: call to notify "done" status
    navigate("/menu", { state: state });
  };

  return (
    <div className={container}>
      <section id="heading">
        <div className={container2}>
          <h2 className={header2}>Thank you!</h2>
          {/*<div className="mb-4 leading-5">Click "back" if you want to change your answers.</div>*/}
          {/*<div className="leading-5">*/}
          {/*  Once you click "I'm done", you will not be able to change your answers.*/}
          {/*</div>*/}
          <ButtonBackNext
            handleBackClick={handleBackClick}
            handleNextClick={handleClick}
            nextTitle="I'm done"
          />
        </div>
      </section>
    </div>
  );
}
