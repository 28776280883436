import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertModal, WhyModal } from "@components";
import { useState } from "react";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import { updatePerson } from "../../../../api/secureApi";
import { useRasaStore } from "../../../../store/zustand";
import { MANDATORY_SURVEY } from "../../../../constants/surveyTypes";
import { handleError } from "../../../../utils/handleError";
import { primaryButton, questionMain3, secondaryButton } from "../../../../constants/classnames";
import { handleLogoutClick } from "../../menu";

export function Parole() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const setProbationOrParoleInStore = useRasaStore((state) => state.setProbationOrParole);
  // eslint-disable-next-line no-console
  console.log("state in parole", state);
  const [open, setOpen] = useState(false);
  const [openWhy, setOpenWhy] = useState(false);
  const [localState, setLocalState] = useState(state);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    // eslint-disable-next-line no-console
    console.log(data);
    const newState = { ...state, ...data };
    setLocalState(newState);
    if (data.on_probation_or_parole === "yes") {
      setOpen(true);
      setProbationOrParoleInStore(true);
    } else {
      const newState = { ...state, ...data };
      // eslint-disable-next-line no-console
      console.log("merged state in parole", newState);
      saveAndContinue(newState);
    }
  };

  const continueClick = () => {
    saveAndContinue(localState);
  };

  const saveAndContinue = (currentState) => {
    const currentMandatorySurvey = currentState.surveys.find(
      (survey) => survey.type === MANDATORY_SURVEY,
    );
    // eslint-disable-next-line no-console
    console.log("currentMandatorySurvey", currentMandatorySurvey);
    const stateToSubmit = {
      profile_id: currentState.profile_id,
      surveys: [
        {
          type: MANDATORY_SURVEY,
          answers: {
            race: currentMandatorySurvey.answers.race,
            race_other: currentMandatorySurvey.answers.race_other || "", // optional field
            ethnicity: currentMandatorySurvey.answers.ethnicity,
            open_cases: currentMandatorySurvey.answers.open_cases,
            on_probation_or_parole: currentState.on_probation_or_parole,
          },
        },
      ],
    };
    // eslint-disable-next-line no-console
    console.log("stateToSubmit in parole", stateToSubmit);
    updatePerson(stateToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in parole", response);
        navigate("/register/other-convictions", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in parole", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className={questionMain3}>
            are you currently on probation or{" "}
            <span className="whitespace-nowrap">
              parole?
              <InformationCircleIcon
                className="relative -top-1 inline h-6 cursor-pointer text-rasapink"
                onClick={() => setOpenWhy(true)}
              />
            </span>
          </h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto my-6 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                <input
                  type="radio"
                  name="paroleRadio"
                  id="parole-yes"
                  className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                  value="yes"
                  aria-invalid="false"
                  aria-describedby="on_probation_or_parole-error"
                  {...register("on_probation_or_parole", { required: true })}
                />
                <label
                  htmlFor="parole-yes"
                  className="block w-full cursor-pointer p-4 font-bold leading-6 text-gray-900"
                >
                  yes
                </label>
              </div>
              <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                <input
                  type="radio"
                  name="paroleRadio"
                  id="parole-no"
                  className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                  value="no"
                  aria-invalid="false"
                  aria-describedby="on_probation_or_parole-error"
                  {...register("on_probation_or_parole", { required: true })}
                />
                <label
                  htmlFor="parole-no"
                  className="block w-full cursor-pointer p-4 font-bold leading-6 text-gray-900"
                >
                  no
                </label>
              </div>
              {errors.on_probation_or_parole && (
                <div className="mt-2 text-sm text-red-600" id="parole-error">
                  Please tell us if you have are on probation or parole.
                </div>
              )}
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                next
              </button>
            </div>
          </div>
        </form>
      </section>
      <WhyModal
        open={openWhy}
        setOpen={setOpenWhy}
        answer="To be eligible for record clearance, a person must be off probation or parole."
      />
      <AlertModal open={open}>
        <div className="mb-4 flex justify-center">
          <ExclamationCircleIcon className="h-20 text-pink-100" />
        </div>
        <AlertDialog.Title className="mb-4 font-serif text-xl">
          at this time, you're likely ineligible for record clearance.
        </AlertDialog.Title>
        <AlertDialog.Description className="mb-4">
          You may have a chance to get your record cleared after you've completed probation or
          parole.
        </AlertDialog.Description>
        <button type="button" className={primaryButton} onClick={continueClick}>
          continue anyway
        </button>
        <button
          type="button"
          className={secondaryButton}
          onClick={() => handleLogoutClick(navigate)}
        >
          log out
        </button>
      </AlertModal>
    </>
  );
}
