import { ChevronLeftIcon } from "@heroicons/react/20/solid";

const buttonClass =
  "mb-4 w-full rounded-full border border-rasapink bg-white py-2 pl-3 pr-6 font-semibold text-rasapink hover:shadow-xl";

export const ButtonBack = ({ title, handleClick }) => {
  return (
    <div>
      <button type="button" className={buttonClass} onClick={handleClick}>
        <div className="flex justify-center">
          <ChevronLeftIcon className="-mt-0.5 h-7 text-rasapink" />
          <div>{title}</div>
        </div>
      </button>
    </div>
  );
};
