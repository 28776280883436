import {
  questionMain,
  question,
  inputWrapper,
  container2,
  textareaClass,
  errorClass,
} from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { getAnswersByQuestion } from "../../../api/secureApi";
import { CAREGIVER } from "./overview";
import { handleError } from "../../../utils/handleError";
import { handleSave } from "./handleSave";
import { RadioPS } from "./radioPS";
import { LOGIN } from "./lifeCircumstances";
import { Header } from "./header";

// allows for multiple
const DETAILS = "details";

export function Caregiver() {
  const navigate = useNavigate();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { getValues, register, setValue, watch } = useForm({
    mode: "all",
    defaultValues: {
      [CAREGIVER]: "",
    },
  });
  const current = watch(CAREGIVER);

  useEffect(() => {
    window.scrollTo(0, 0);

    getAnswersByQuestion(CAREGIVER)
      .then((response) => {
        response.forEach((row) => {
          setValue(CAREGIVER, row.selection);
          if (row.additional_json) {
            if (row.additional_json[DETAILS]) {
              setValue(DETAILS, row.additional_json[DETAILS]);
            }
          }
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error getting answers", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  }, [setValue, navigate]);

  useEffect(() => {
    if (current === "no") {
      setGenericErrorMessage("");
    }
  }, [current]);

  const handlePreSave = (path) => {
    const values = getValues();
    if (values[CAREGIVER]) {
      if (values[CAREGIVER] === "yes" && values[DETAILS]) {
        // eslint-disable-next-line no-console
        console.log("values", values);
        setGenericErrorMessage("");
        const newDetails = [
          {
            selection: values[CAREGIVER],
            additional_json: {
              [DETAILS]: values[DETAILS],
            },
          },
        ];
        handleSave(CAREGIVER, newDetails, navigate, path);
      } else if (values[CAREGIVER] === "no") {
        setGenericErrorMessage("");
        const newDetails = [
          {
            selection: values[CAREGIVER],
          },
        ];
        handleSave(CAREGIVER, newDetails, navigate, path);
      } else if (values[CAREGIVER] === "yes" && !values[DETAILS]) {
        setGenericErrorMessage("Please fill in details.");
      }
    } else {
      navigate(path);
    }
  };

  const handleBackClick = () => {
    const path = "/statement/grandchildren";
    handlePreSave(path);
  };

  const handleNextClick = () => {
    const path = "/statement/housing";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section id="menu">
        <div className={container2}>
          <h2 className={questionMain}>are you a caregiver to someone who is not your child?</h2>
          <div className="pb-4">
            <RadioPS
              name={CAREGIVER}
              answer="yes"
              label="yes"
              register={register}
              {...register(CAREGIVER)}
              current={current}
            />
            <RadioPS
              name={CAREGIVER}
              answer="no"
              label="no"
              register={register}
              {...register(CAREGIVER)}
              current={current}
            />
          </div>
          {current === "yes" && (
            <div>
              <h2 className={question}>please give details here</h2>
              <div className={inputWrapper}>
                <textarea
                  placeholder="we care for my mother-in-law, who lives with us"
                  type="text"
                  name={DETAILS}
                  id={DETAILS}
                  className={textareaClass}
                  rows="4"
                  {...register(DETAILS)}
                ></textarea>
              </div>
            </div>
          )}
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <ButtonBackNext
            handleBackClick={handleBackClick}
            handleNextClick={handleNextClick}
            disabledNext={!current}
          />
        </div>
      </section>
    </>
  );
}
