import { animate, inputWrapper, question, textareaClassPink } from "../../../constants/classnames";
import { handleDetailsChange } from "./handleSave";
import { DETAILS } from "./barriers";

export const BarrierDetails = ({ selection, details, setDetails, placeholder }) => {
  return (
    <div className={animate}>
      <div className={question}>please give brief details</div>
      <div className={inputWrapper}>
        <textarea
          placeholder={placeholder}
          type="text"
          name={DETAILS}
          id={DETAILS}
          className={textareaClassPink}
          rows="3"
          onChange={(e) => handleDetailsChange(e, selection, DETAILS, details, setDetails)}
          defaultValue={
            details.find(
              (item) =>
                item.selection === selection &&
                item.additional_json &&
                item.additional_json[DETAILS],
            )?.additional_json[DETAILS] || ""
          }
        ></textarea>
      </div>
    </div>
  );
};
