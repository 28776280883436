import {
  container2,
  question,
  questionMain,
  errorClass,
  textareaClass,
  pinkSection,
  animate,
} from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { getAnswersByQuestion } from "../../../api/secureApi";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { MARITAL_STATUS } from "./overview";
import { handleError } from "../../../utils/handleError";
import { handleSave } from "./handleSave";
import { RadioPS } from "./radioPS";
import { LOGIN } from "./lifeCircumstances";
import { Header } from "./header";
import { getNumberOptions } from "../register/surveyOptional/options";
import { SelectSmall } from "../../../components/SelectSmall";

const HOW_LONG_MARRIED = "how_long_married";
const DETAILS = "details";

export function MaritalStatus() {
  const navigate = useNavigate();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const [numberOptions] = useState(() => getNumberOptions(50));
  const { getValues, register, setValue, watch } = useForm({
    mode: "all",
    defaultValues: {
      [MARITAL_STATUS]: "",
    },
  });
  const current = watch(MARITAL_STATUS);

  useEffect(() => {
    window.scrollTo(0, 0);

    getAnswersByQuestion(MARITAL_STATUS)
      .then((response) => {
        response.forEach((row) => {
          setValue(MARITAL_STATUS, row.selection);
          if (row.additional_json) {
            if (row.additional_json[HOW_LONG_MARRIED]) {
              setValue(HOW_LONG_MARRIED, {
                value: row.additional_json[HOW_LONG_MARRIED],
                label: row.additional_json[HOW_LONG_MARRIED],
              });
            }
            if (row.additional_json[DETAILS]) {
              setValue(DETAILS, row.additional_json[DETAILS]);
            }
          }
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error getting answers", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  }, [setValue, navigate]);

  const handlePreSave = (path) => {
    const values = getValues();
    if (values[MARITAL_STATUS]) {
      if (values[MARITAL_STATUS] === "married" && values[HOW_LONG_MARRIED]) {
        // eslint-disable-next-line no-console
        console.log("values", values);
        setGenericErrorMessage("");
        let howLongMarried = values[HOW_LONG_MARRIED];
        if (typeof howLongMarried !== "string") {
          howLongMarried = values[HOW_LONG_MARRIED].value;
        }
        const newDetails = [
          {
            selection: values[MARITAL_STATUS],
            additional_json: {
              [HOW_LONG_MARRIED]: howLongMarried,
            },
          },
        ];
        handleSave(MARITAL_STATUS, newDetails, navigate, path);
      } else if (values[MARITAL_STATUS] === "married" && !values[HOW_LONG_MARRIED]) {
        setGenericErrorMessage("Please fill in details.");
      } else {
        setGenericErrorMessage("");
        const newDetails = [
          {
            selection: values[MARITAL_STATUS],
          },
        ];
        handleSave(MARITAL_STATUS, newDetails, navigate, path);
      }
    } else {
      navigate(path);
    }
  };

  const handleBackClick = () => {
    const path = "/statement/therapy";
    handlePreSave(path);
  };

  const handleNextClick = () => {
    const path = "/statement/children";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section id="heading">
        <div className={container2}>
          <div className={questionMain}>what is your marital status?</div>
          <div className="pb-4">
            <RadioPS
              name={MARITAL_STATUS}
              answer="single"
              label="single"
              register={register}
              {...register(MARITAL_STATUS)}
              current={current}
            />
            <div className={current === "married" ? pinkSection : ""}>
              <RadioPS
                name={MARITAL_STATUS}
                answer="married"
                label="married"
                register={register}
                {...register(MARITAL_STATUS)}
                current={current}
              />
              {current === "married" && (
                <div className={animate}>
                  <div className={question}>how long have you been married?</div>
                  <div className="flex">
                    <div>
                      <SelectSmall
                        id={HOW_LONG_MARRIED}
                        name={HOW_LONG_MARRIED}
                        options={numberOptions}
                        defaultValue={getValues(HOW_LONG_MARRIED)}
                        register={register}
                        {...register("how_long_married", { required: true })}
                      />
                    </div>
                    <div className="ml-2 mt-2">years</div>
                  </div>
                </div>
              )}
            </div>
            <RadioPS
              name={MARITAL_STATUS}
              answer="widowed"
              label="widowed"
              register={register}
              {...register(MARITAL_STATUS)}
              current={current}
            />
            <RadioPS
              name={MARITAL_STATUS}
              answer="divorced"
              label="divorced"
              register={register}
              {...register(MARITAL_STATUS)}
              current={current}
            />
            <RadioPS
              name={MARITAL_STATUS}
              answer="I prefer not to answer"
              label="I prefer not to answer"
              register={register}
              {...register(MARITAL_STATUS)}
              current={current}
            />
          </div>
          <div className={question}>please give details here, if needed</div>
          <textarea
            placeholder="I'm engaged to my girlfriend. We've been together for 2 years."
            type="text"
            name={DETAILS}
            id={DETAILS}
            className={textareaClass}
            rows="4"
            {...register(DETAILS)}
          ></textarea>
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <ButtonBackNext
            handleBackClick={handleBackClick}
            handleNextClick={handleNextClick}
            disabledNext={!current}
          />
        </div>
      </section>
    </>
  );
}
