export const AutoExpungementNote = () => {
  return (
    <>
      <div className="mt-6 rounded-md bg-amber-100 p-2">
        <b>Note:</b> This case may be eligible for automatic record clearance under Utah's Clean
        Slate law. Under legislation that went into effect in October 2024, individuals must now
        fill out a form to be eligible for automatic expungement. That form can be found{" "}
        <span className="text-rasapink underline">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.utcourts.gov/en/self-help/case-categories/criminal-justice/expunge.html#accordion-e952617792-item-2d3c0c94ad"
          >
            here
          </a>
        </span>
        . While it is free of charge to file the form, it is unclear how long it will take the
        courts or BCI to process an automatic expungement. Under the new law, BCI must prioritize
        petition-based expungements.{" "}
        <span className="text-rasapink underline">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://le.utah.gov/xcode/Title77/Chapter40A/77-40a-S401.html"
          >
            <i>See</i> Utah Code 77-40a-401(1).
          </a>
        </span>
      </div>
    </>
  );
};
