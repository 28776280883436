import { container2, errorClass, questionMain } from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { RadioPS } from "./radioPS";
import { useForm } from "react-hook-form";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { useEffect, useRef, useState } from "react";
import { handleSave } from "./handleSave";
import { PROBATION_COMPLIANCE } from "./overview";
import { getAnswersByQuestion } from "../../../api/secureApi";
import { handleError } from "../../../utils/handleError";
import { LOGIN } from "./lifeCircumstances";
import { Header } from "./header";

const PROBATION_DISCHARGE = "probation_discharge";
const answers = [
  "probation not ordered",
  "asked the court for early termination and it was granted",
  "complied with probation and completed",
  "had some probation violations but eventually completed",
  "probation revoked and incarcerated",
];

export function ProbationCompliance() {
  const navigate = useNavigate();
  const ref0 = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { getValues, register, setValue, watch } = useForm({
    mode: "all",
    defaultValues: {
      [PROBATION_COMPLIANCE]: "",
      [PROBATION_DISCHARGE]: "",
    },
  });
  const current = watch(PROBATION_COMPLIANCE);
  // eslint-disable-next-line no-console
  console.log("current in probation:", current);
  // eslint-disable-next-line no-console
  console.log("!current:", !current);

  useEffect(() => {
    window.scrollTo(0, 0);

    getAnswersByQuestion(PROBATION_COMPLIANCE)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in probation", response);
        response.forEach((row) => {
          setValue(PROBATION_COMPLIANCE, row.selection);
          // setCurrent(row.selection);
          if (row.additional_json && row.additional_json[PROBATION_DISCHARGE]) {
            setValue(PROBATION_DISCHARGE, row.additional_json[PROBATION_DISCHARGE]);
          }
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error getting answers", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  }, [setValue, navigate]);

  const handlePreSave = (path) => {
    const probation = getValues(PROBATION_COMPLIANCE);
    if (probation) {
      const probation_discharge = getValues(PROBATION_DISCHARGE);
      if (probation || probation_discharge) {
        const newDetails = [
          {
            selection: probation,
            additional_json: {
              probation_discharge: probation_discharge,
            },
          },
        ];
        handleSave(PROBATION_COMPLIANCE, newDetails, navigate, path);
      }
    } else {
      navigate(path);
    }
  };

  const handleBackClick = () => {
    const path = "/statement/life-circumstances";
    handlePreSave(path);
  };

  const handleNextClick = () => {
    const path = "/statement/barriers";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section id="menu">
        <div className={container2}>
          <div className={questionMain}>What was your experience on probation?</div>
          <div className="pb-4">
            <RadioPS
              innerRef={ref0}
              name={PROBATION_COMPLIANCE}
              answer={answers[0]}
              label={answers[0]}
              register={register}
              {...register(PROBATION_COMPLIANCE)}
              current={current}
            />
            <RadioPS
              innerRef={ref1}
              name={PROBATION_COMPLIANCE}
              answer={answers[1]}
              label={answers[1]}
              register={register}
              {...register(PROBATION_COMPLIANCE)}
              current={current}
            />
            <RadioPS
              innerRef={ref2}
              name={PROBATION_COMPLIANCE}
              answer={answers[2]}
              label={answers[2]}
              register={register}
              {...register(PROBATION_COMPLIANCE)}
              current={current}
            />
            <RadioPS
              innerRef={ref3}
              name={PROBATION_COMPLIANCE}
              answer={answers[3]}
              label={answers[3]}
              register={register}
              {...register(PROBATION_COMPLIANCE)}
              current={current}
            />
            <RadioPS
              innerRef={ref3}
              name={PROBATION_COMPLIANCE}
              answer={answers[4]}
              label={answers[4]}
              register={register}
              {...register(PROBATION_COMPLIANCE)}
              current={current}
            />
          </div>
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <ButtonBackNext
            handleBackClick={handleBackClick}
            handleNextClick={handleNextClick}
            disabledNext={!current}
          />
        </div>
      </section>
    </>
  );
}
