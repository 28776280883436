import RasaLogo from "@images/rasa-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { flexCenter, logo, menu, menuButtonClass } from "../../constants/classnames";
import { useEffect, useState } from "react";
import { UT } from "../../constants/usStates";
import { getAnswersAll, getStatus } from "../../api/secureApi";
import { EXPUNGEMENT } from "./matterStatus/constants";
import { bciAppPhase, needPS } from "./matterStatus/utils";

export const cleanLocalStorage = () => {
  localStorage.setItem("token", "");
  localStorage.setItem("profile_id", "");
  localStorage.setItem("us_state", "");
  localStorage.setItem("title", "");
  localStorage.setItem("email", "");
  localStorage.setItem("show_clio_link", "");
  localStorage.setItem("utm_code", "");
};

export const handleLogoutClick = (navigate) => {
  cleanLocalStorage();
  navigate("/login");
};

const MenuButton = ({ onClick, title }) => {
  return (
    <div className="text-center">
      <button type="button" className={menuButtonClass} onClick={onClick}>
        {title}
      </button>
    </div>
  );
};

export function Menu() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [hasUtahMatters, setHasUtahMatters] = useState(false);
  const [hasArizonaMatters, setHasArizonaMatters] = useState(false);
  const [showClio, setShowClio] = useState(false);
  const [answerCount, setAnswerCount] = useState(0);

  useEffect(() => {
    const us_state = localStorage.getItem("us_state");
    getStatus()
      .then((response) => {
        const matters = response?.matters;
        if (matters) {
          if (us_state === UT) {
            setHasUtahMatters(!!matters.length);
            const show_clio_link = localStorage.getItem("show_clio_link");
            setShowClio(show_clio_link === "true");
            if (!show_clio_link) {
              const showClioLink = matters.some((m) => {
                if (m.workflow && m.workflow_stage) {
                  if (m.legal_action_type === EXPUNGEMENT) {
                    return (
                      !m.workflow?.toLowerCase()?.includes("bci phase") ||
                      (!m.workflow_stage?.toLowerCase()?.includes(bciAppPhase) &&
                        !m.workflow_stage?.toLowerCase()?.includes(needPS))
                    );
                  } else {
                    return true;
                  }
                }
              });
              setShowClio(!!showClioLink);
              localStorage.setItem("show_clio_link", showClioLink);
            }
          } else {
            setHasArizonaMatters(true);
            // eslint-disable-next-line no-console
            console.log("response", response);
            // eslint-disable-next-line no-console
            console.log("matters", matters);
          }
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error counting matters", error);
      });
  }, []);

  useEffect(() => {
    getAnswersAll()
      .then((response) => {
        setAnswerCount(response.length);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error getting all answers", error);
      });
  }, []);

  const goToResults = () => {
    navigate("/results", { state: state });
  };

  const goToMatterStatus = () => {
    navigate("/matter-status");
  };

  const goToClioPage = () => {
    navigate("/clio-page");
  };

  const goToPersonalStatementInfo = () => {
    if (answerCount > 10) {
      navigate("/statement-overview");
    } else {
      navigate("/statement/intro");
    }
  };

  const handleLogoutClick = () => {
    cleanLocalStorage();
    navigate("/login");
  };

  return (
    <>
      <div className="container relative mx-auto pb-6 pt-10">
        <div className={flexCenter}>
          <div className={logo}>
            <RasaLogo className="h-7" />
          </div>
        </div>
      </div>
      <div id="menu" className={menu}>
        <div>
          <MenuButton onClick={goToResults} title="eligibility results" />
          <MenuButton onClick={goToPersonalStatementInfo} title="personal statement questions" />
          {hasUtahMatters && (
            <>
              <MenuButton onClick={goToMatterStatus} title="your cases" />
              {showClio && <MenuButton onClick={goToClioPage} title="clio for clients" />}
            </>
          )}
          {hasArizonaMatters && (
            <div>
              <MenuButton title="your cases" />
              <div className="-mt-6 text-center italic">(this feature coming for AZ soon)</div>
            </div>
          )}
          <MenuButton onClick={handleLogoutClick} title="log out" />
        </div>
      </div>
    </>
  );
}
